
::v-deep {
    .v-color-picker__swatches > div {
        padding: 0;
    }

    .v-color-picker__swatch {
        margin: 0;
    }
    .v-color-picker__color {
        margin: 2px;
        width: 22px;
        height: 22px;
        max-height: 22px;
        border-radius: 50%;
    }
}
