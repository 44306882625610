
::v-deep {
    .item--canceled {
        color: rgba(0, 0, 0, 0.5);

        .purchase-item-image {
            opacity: 0.5;
        }
    }
}
