
::v-deep {
    .v-input__slot {
        background-color: rgba(255, 255, 255, 0) !important;

        &:hover {
            background: rgba(255, 255, 255, 0.75) !important;
        }
    }
}
.product-options-table ::v-deep {
    th:first-of-type,
    td:first-of-type {
        width: 64px !important;
        text-align: center !important;
        border-right: thin solid rgba(0, 0, 0, 0.12);

        .v-simple-checkbox {
            display: inline-block;
        }

        .v-input--selection-controls__input {
            margin: 0;
        }
    }
}
