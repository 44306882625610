
::v-deep {
    .v-data-table__mobile-row {
        height: auto !important;
        max-height: auto !important;
        min-height: auto !important;

        &:first-of-type {
            padding-top: 12px;
        }
        &:last-of-type {
            padding-bottom: 12px;
        }
    }
    .v-data-table__mobile-row__header {
        flex: 0 0 25%;
    }
}
